<template>
	<div class="page">
		<div class="page-top">
			<div class="title">房屋智能估价</div>
			<div class="btn" @click="toTask">
				<div class="icon"></div>
				任务管理
			</div>
			<div class="btn" @click="toSearch">
				<div class="icon"></div>
				估价查询
			</div>
			<div class="btn" @click="showModal">
				<div class="icon"></div>
				估价流程
			</div>
		</div>

		<div class="info-box">
			<div class="info-item" v-for="(item, index) in infoList" :key="index" :class="[item.class]">
				<div class="left">
					<div class="icon"></div>
					<div class="txt">{{ item.txt }}</div>
				</div>
				<div class="right" v-if="index == 0">
					<div class="line">
						<div class="line-left">任务数</div>
						<div class="line-right">{{ tjData.ongoingTaskCnt }}</div>
					</div>
					<div class="line">
						<div class="line-left">房屋数量</div>
						<div class="line-right">{{ tjData.ongoingYpCnt }}笔</div>
					</div>
				</div>
				<div class="right" v-if="index == 1">
					<div class="line">
						<div class="line-left">任务数</div>
						<div class="line-right">{{ tjData.doneTaskCnt }}</div>
					</div>
					<div class="line">
						<div class="line-left">房屋数量</div>
						<div class="line-right">{{ tjData.doneTaskYpCnt }}笔</div>
					</div>
					<div class="line">
						<div class="line-left">出值率</div>
						<div class="line-right">
							{{
                tjData.donePgValueRate != ""
                  ? +(tjData.donePgValueRate / 1).toFixed(2)
                  : ""
              }}%
						</div>
					</div>
				</div>
				<div class="right" v-if="index == 2">
					<div class="line">
						<div class="line-left">任务数</div>
						<div class="line-right">{{ tjData.totalTaskCnt }}</div>
					</div>
					<div class="line">
						<div class="line-left">房屋数量</div>
						<div class="line-right">{{ tjData.totalTaskYpCnt }}笔</div>
					</div>
					<!-- <div class="line">
						<div class="line-left">出值率</div>
						<div class="line-right">{{tjData.totalPgValueRate}}%</div>
          </div>-->
				</div>
				<div class="right" v-if="index == 3">
					<div class="line">
						<div class="line-left">任务数</div>
						<div class="line-right">{{ tjData.yjTaskCnt }}</div>
					</div>
					<div class="line">
						<div class="line-left">房屋数量</div>
						<div class="line-right">{{ tjData.yjYpCnt }}笔</div>
					</div>
					<!--<div class="line">
						<div class="line-left">出值率</div>
						<div class="line-right">{{tjData.yjYpRate}}%</div>
          </div>-->
				</div>
			</div>
		</div>

		<div class="map-box">
			<div id="map"></div>
			<div class="des-box">
				<div class="box-top">估价概况</div>
				<div class="box-ct">
					<div class="des-item">
						<div class="icon"></div>
						<div class="right">
							<div class="txt">房屋数量</div>
							<div class="val">
								<span>{{
                  tabIndex == 0 ? tjData.doneTaskYpCnt : tjData.yjYpCnt
                }}</span>笔
							</div>
						</div>
					</div>
					<div class="des-item">
						<div class="icon"></div>
						<div class="right">
							<div class="txt">总估值</div>
							<div class="val">
								<span>{{
                  +(
                    (tabIndex == 0
                      ? tjData.totalPgAmount
                      : tjData.yjYpPgAmount) / 100000000
                  ).toFixed(2)
                }}</span>亿元
							</div>
						</div>
					</div>
					<div class="des-item">
						<div class="icon"></div>
						<div class="right">
							<div class="txt">分布城市</div>
							<div class="val" v-if="tabIndex == 0">
								<span>{{ tjData.cityCnt }}</span>个
							</div>
							<div class="val" v-if="tabIndex == 1">
								<span>{{ tjData.yjYpCityCnt }}</span>个
							</div>
						</div>
					</div>
				</div>
				<div class="box-bottom">
					<div class="btn" v-for="(item, index) in tabList" :key="index" @click="changeTabIndex(index)"
						:class="{ active: index == tabIndex }">
						<div class="icon"></div>
						<div class="txt">{{ item }}</div>
					</div>
				</div>
			</div>
			<div class="table-box" :style="tableStyle" :class="{ active: showTable }">
				<div class="box-top">
					<div class="title">房屋分布</div>
					<div class="icon arrow" @click="switchTable"></div>
				</div>
				<Table :columns="tableColumns" tooltip-theme="light" :data="tableData" border height="240" class="table"
					:loading="loading"></Table>
			</div>
		</div>
		<Modal :closable="false" v-model="liuchengModal" @on-ok="hideModal" @on-cancel="hideModal"
			class-name="vertical-center-modal" :mask-closable="false" footer-hide width='530'>
			<div>
				<div style="margin:16px 20px 16px 30px;font-size:20px">估价流程</div>
				<p style="padding:5px 30px"> <b>步骤1：</b> 用户上传证载地址；</p>
				<p style="padding:5px 30px"><b>步骤2：</b>系统自动对数据进行校验；</p>
				<p style="padding:5px 30px">
					<b>步骤3：</b>通过大数据算法将证载地址与系统楼盘字典、房屋字典进行智能匹配，找到证载地址所属小区、完善所属房屋信息，并通过人工审核校正；如果匹配不上，则用证载地址周边5公里范围内的小区交易数据进行估价；如果周边5公里范围内的小区也无数据，则扩大范围值行政区或全市。这一步的目的是完善证载地址对应房屋的信息，例如房屋户型、朝向、楼层等，使估价结果准确。
				</p>
				<p style="padding:5px 30px">
					<b>步骤4：</b>基于平台海量数据，使用机器学习算法对待估价房屋的价值进行估价，生成房产估值；
				</p>
				<p style="padding:5px 30px"><b>步骤5：</b>用户可进行参数设置和房屋管理；</p>
				<p style="padding:5px 30px">
					<b>步骤6：</b>确认估价结果后，用户可根据筛选条件对估价结果进行查询，系统自动生成可视化估价结果和估价报告；
				</p>
				<p style="padding:5px 30px">
					<b>步骤7：</b>系统每隔6个月自动对房屋价值重估一次；
				</p>
				<p style="padding:16px 30px">
					注：房屋价值估价和租金估价流程相同；参数设置中，房屋价值调整和租金调整是用户行为，不影响系统估价结果。
				</p>
			</div>
			<Button type="primary" size='large' style="position: relative;left: 50%;transform: translate(-50%);"
				@click="hideModal">我知道了</Button>
		</Modal>
		<Spin size="large" fix v-if="showSpin"></Spin>
		<!-- <ShowOfficeTips :single='false'></ShowOfficeTips> -->
	</div>
</template>

<script>
	// @ is an alias to /src
	const echarts = require("echarts");
import _ from 'lodash'
	// import china from "echarts/map/json/china.json";
	import chinaJson from "echarts/map/json/china.json";
	import anhui from "echarts/map/json/province/anhui.json";
	// import aomen from "echarts/map/json/province/aomen.json";
	//import beijing from "echarts/map/json/province/beijing.json";
	//import chongqing from "echarts/map/json/province/chongqing.json";
	import fujian from "echarts/map/json/province/fujian.json";
	import gansu from "echarts/map/json/province/gansu.json";
	import guangdong from "echarts/map/json/province/guangdong.json";
	// import guangdong from "echarts/map/json/province/guangdong.json";
	import guangxi from "echarts/map/json/province/guangxi.json";
	import guizhou from "echarts/map/json/province/guizhou.json";
	import hainan from "echarts/map/json/province/hainan.json";
	import hebei from "echarts/map/json/province/hebei.json";
	import heilongjiang from "echarts/map/json/province/heilongjiang.json";
	import henan from "echarts/map/json/province/henan.json";
	import hubei from "echarts/map/json/province/hubei.json";
	import hunan from "echarts/map/json/province/hunan.json";
	import jiangsu from "echarts/map/json/province/jiangsu.json";
	import jiangxi from "echarts/map/json/province/jiangxi.json";
	import jilin from "echarts/map/json/province/jilin.json";
	import liaoning from "echarts/map/json/province/liaoning.json";
	import neimenggu from "echarts/map/json/province/neimenggu.json";
	import ningxia from "echarts/map/json/province/ningxia.json";
	import qinghai from "echarts/map/json/province/qinghai.json";
	import shandong from "echarts/map/json/province/shandong.json";
	//import shanghai from "echarts/map/json/province/shanghai.json";
	import shanxi from "echarts/map/json/province/shanxi.json";
	import shanxi1 from "echarts/map/json/province/shanxi1.json";
	import sichuan from "echarts/map/json/province/sichuan.json";
	// import taiwan from "echarts/map/json/province/taiwan.json";
	//import tianjin from "echarts/map/json/province/tianjin.json";
	//import xianggang from "echarts/map/json/province/xianggang.json";
	import xinjiang from "echarts/map/json/province/xinjiang.json";
	//import nanhai from "echarts/map/json/provincevince/nanhaizhudao.json";
	import yunnan from "echarts/map/json/province/yunnan.json";
	import zhejiang from "echarts/map/json/province/zhejiang.json";
	// import xizang from "echarts/map/json/province/xizang.json";
	import xizang from "../../../public/json/map/xizang.json";
	import dataReports from "@/mixins/dataReports";
	// import ShowOfficeTips from "@/components/showOfficeTips";
	import {
		yptj,
		ypQyfb
	} from "@/api/pledge";

	export default {
		name: "taskManage",
		components: {
			// ShowOfficeTips
		},
		mixins: [dataReports],
		data() {
			return {
				infoList: [{
						txt: "进行中",
						class: "",
					},
					{
						txt: "完成",
						class: "",
					},
					{
						txt: "合计",
						class: "",
					},
					{
						txt: "风险预警",
						class: "danger",
					},
				],

				tabIndex: 0,
				tabList: ["房屋分布", "风险分布"],

				loading: false,
				showTable: true,
				tableData: [],
				tableColumns: [{
						title: "城市",
						key: "cs",
						align: "center",
					},
					{
						title: "押品数量（笔）",
						renderHeader: (h, index) => {
							return h("div", [h("div", "房屋数量"), h("div", "（笔）")]);
						},
						key: "cityYpCnt",
						align: "center",
					},
					{
						title: "比重",
						key: "cityYpRate",
						align: "center",
						render: (h, params) => {
							return h(
								"div",
								params.row.cityYpRate == "" ?
								"" :
								+params.row.cityYpRate.toFixed(2) + "%"
							);
						},
					},
					{
						title: "总估值（亿元）",
						renderHeader: (h, index) => {
							return h("div", [h("div", "总估值"), h("div", "（亿元）")]);
						},
						key: "cityYpPgAmount",
						align: "center",
					},

					{
						title: "平均价格（元/平方）",
						renderHeader: (h, index) => {
							return h("div", [
								h("div", "平均价格"),
								h("div", "（元/平方）"),
							]);
						},
						key: "cityAvgPrice",
						align: "center",
					},
					{
						title: "租金价格（元/平方/月）",
						renderHeader: (h, index) => {
							return h("div", [h("div", "租金价格"), h("div", "（元/平方/月）")]);
						},
						key: "cityRentPrice",
						align: "center",
					},
					{
						title: "风险预警数量",
						key: "cityYjYpCnt",
						align: "center",
					},
					{
						title: "预警比重",
						key: "cityYjYpRate",
						align: "center",
						render: (h, params) => {
							return h(
								"div",
								params.row.cityYjYpRate == "" ?
								"" :
								+params.row.cityYjYpRate.toFixed(2) + "%"
							);
						},
					},
					{
						title: "风险预警总值",
						key: "cityYjYpPgAmount",
						align: "center",
					},
				],
				ypzl: 0,
				zgz: 0,
				fbcs: "--",
				tjData: {},
				desInfo: {
					yjYpPgAmount: 0,
					cityYpPgAmount: 0,
					cityCnt: 0,
					yjYpCityCnt: 0,
				},

				// 总数据
				totalData: [],
				// 预警数据
				warnData: [],

				mapCityList: [],
				cityArr: [
					"北京",
					"上海",
					"天津",
					"重庆",
					"香港",
					"澳门",
					"台湾",
					"钓鱼岛",
					"南海诸岛",
				],
				showSpin: false,
				liuchengModal: false,
				timer: null,
			};
		},
		computed: {
			tableStyle() {
				let val = 0;
				if (!this.showTable) {
					val = -240;
				}
				return {
					bottom: `${val}px`,
				};
			},

		},

		beforeMount() {
			this.$Notice.destroy();
		},
		mounted() {
			// this.mapChart("map");
			// echarts.dispose()
			this.init();
		},
		beforeDestroy() {
			clearInterval(this.timer);
			// chinaJson.UTF8Encoding = false
			// echarts.dispose()
			this.timer = null;
		},

		methods: {
			info() {

			},
			init() {
				this.info();
				this.showSpin = true;
				setTimeout(() => {
					this._yptj();
					this._ypQyfb();
				}, 500);
			},
			toTask() {
				this.$router.push({
					path: "/Empowerment/pledge/pledgeManage/taskManage",
				});
			},
			toSearch() {
				this.$router.push({
					path: "/Empowerment/pledge/pledgeManage/pledgeSearch",
				});
			},
			_yptj() {
				yptj({}).then((data) => {
					let info = (this.tjData = data[0]);
					let desInfo = this.desInfo;

					desInfo.yjYpPgAmount = info.totalTaskYpCnt;
					desInfo.cityYpPgAmount = +(info.totalPgAmount / 100000000).toFixed(2);
					desInfo.cityCnt = info.cityCnt;
					desInfo.yjYpCityCnt = info.yjYpCityCnt;
				});
			},
			_ypQyfb() {
				ypQyfb().then((data) => {
					this.totalData = this.tableData = data;
					this.fbcs = data.length;
					this.warnData = data.filter((obj) => {
						obj.cityYpPgAmount = +(obj.cityYpPgAmount / 100000000).toFixed(2);
						if (obj.cityYjYpPgAmount > 100000000) {
							obj.cityYjYpPgAmount = +(obj.cityYjYpPgAmount / 100000000).toFixed(2) + "亿元";
						} else if (obj.cityYjYpPgAmount > 10000) {
							obj.cityYjYpPgAmount = +(obj.cityYjYpPgAmount / 10000).toFixed(2) + "万元";
						}
						this.ypzl += obj.cityYpCnt;
						this.zgz += obj.cityYpPgAmount;

						if (obj.cityYjYpCnt > 0) {
							return obj;
						}
					});

					this.initMap(data);
				});
			},
			changeTabIndex(index) {
				this.tabIndex = index;
				this.setMap();
			},
			switchTable() {
				this.showTable = !this.showTable;
			},

			initMap(info) {
				this.chart = echarts.init(document.getElementById("map"));

 window.onresize = this.chart.resize;
				const mapName = "china";
		let china = _.cloneDeep(chinaJson)
		// let china = chinaJson
				china.features = china.features.filter((item) => {
					const name = item.properties.name;
					if (this.cityArr.indexOf(name) >= 0) {
						return true;
					}
				});
				let anhuifeatures =_.cloneDeep(anhui.features)
				let fujianfeatures =_.cloneDeep(fujian.features)
				let gansufeatures =_.cloneDeep(gansu.features)
				let guangdongfeatures =_.cloneDeep(guangdong.features)
				let guangxifeatures =_.cloneDeep(guangxi.features)
				let guizhoufeatures =_.cloneDeep(guizhou.features)
				let hainanfeatures =_.cloneDeep(hainan.features)
				let hebeifeatures =_.cloneDeep(hebei.features)
				let hunanfeatures =_.cloneDeep(hunan.features)
				let heilongjiangfeatures =_.cloneDeep(heilongjiang.features)
				let henanfeatures =_.cloneDeep(henan.features)
				let hubeifeatures =_.cloneDeep(hubei.features)
				let jiangsufeatures =_.cloneDeep(jiangsu.features)
				let jiangxifeatures =_.cloneDeep(jiangxi.features)
				let jilinfeatures =_.cloneDeep(jilin.features)
				let liaoningfeatures =_.cloneDeep(liaoning.features)
				let neimenggufeatures =_.cloneDeep(neimenggu.features)
				let ningxiafeatures =_.cloneDeep(ningxia.features)
				let qinghaifeatures =_.cloneDeep(qinghai.features)
				let shandongfeatures =_.cloneDeep(shandong.features)
				let xizangfeatures =_.cloneDeep(xizang.features)
				let shanxifeatures =_.cloneDeep(shanxi.features)
				let sichuanfeatures =_.cloneDeep(sichuan.features)
				let shanxi1features =_.cloneDeep(shanxi1.features)
				let xinjiangfeatures =_.cloneDeep(xinjiang.features)
				let yunnanfeatures =_.cloneDeep(yunnan.features)
				let zhejiangfeatures =_.cloneDeep(zhejiang.features)
				china.features.push(...anhuifeatures);
				china.features.push(...fujianfeatures);
				china.features.push(...gansufeatures);
				china.features.push(...guangdongfeatures);
				china.features.push(...guangxifeatures);
				china.features.push(...guizhoufeatures);
				china.features.push(...hainanfeatures);
				china.features.push(...hebeifeatures);
				china.features.push(...hunanfeatures);
				china.features.push(...heilongjiangfeatures);
				china.features.push(...henanfeatures);
				china.features.push(...hubeifeatures);
				china.features.push(...jiangsufeatures);
				china.features.push(...jiangxifeatures);
				china.features.push(...jilinfeatures);
				china.features.push(...liaoningfeatures);
				china.features.push(...neimenggufeatures);
				china.features.push(...ningxiafeatures);
				china.features.push(...qinghaifeatures);
				china.features.push(...shandongfeatures);
				china.features.push(...xizangfeatures);
				china.features.push(...shanxifeatures);
				china.features.push(...sichuanfeatures);
				china.features.push(...shanxi1features);
				china.features.push(...xinjiangfeatures);
				china.features.push(...yunnanfeatures);
				china.features.push(...zhejiangfeatures);

				// china.features.push(...anhui.features);
				// china.features.push(...fujian.features);
				// china.features.push(...gansu.features);
				// china.features.push(...guangdong.features);
				// china.features.push(...guangxi.features);
				// china.features.push(...guizhou.features);
				// china.features.push(...hainan.features);
				// china.features.push(...hebei.features);
				// china.features.push(...heilongjiang.features);
				// china.features.push(...henan.features);
				// china.features.push(...hubei.features);
				// china.features.push(...hunan.features);
				// china.features.push(...jiangsu.features);
				// china.features.push(...jiangxi.features);
				// china.features.push(...jilin.features);
				// china.features.push(...liaoning.features);
				// china.features.push(...neimenggu.features);
				// china.features.push(...ningxia.features);
				// china.features.push(...qinghai.features);
				// china.features.push(...shandong.features);
				// china.features.push(...xizang.features);
				// china.features.push(...shanxi.features);
				// china.features.push(...sichuan.features);
				// china.features.push(...shanxi1.features);
				// china.features.push(...xinjiang.features);
				// china.features.push(...yunnan.features);
				// china.features.push(...zhejiang.features);
				// china.features.push(...nanhai.features)

				echarts.registerMap("china", china);
				this.allCityName = china.features.map((item) => {
					return item.properties.name;
				});

				let nameList = [];
				let mapKey = {};
				let mapInfo = info.map((item) => {
					// let name = cityArr.indexOf(item.areaName) >= 0 ? item.areaName : `${item.areaName}市`;
					let name = item.cs;
					if (this.cityArr.indexOf(name) < 0) {
						this.allCityName.some((item) => {
							if (item.indexOf(name) == 0) {
								mapKey[name] = item;
								name = item;
								return true;
							}
						});
					}
					nameList.push(name);
					return {
						name,
						value: item.cityYpCnt || 0,
						...item,
					};
				});
				let itemStyle = {
					normal: {
						borderWidth: 2,
						borderColor: "#fff",
					},
				};

				this.option = {
					backgroundColor: "#1E2E54",
					tooltip: {
						trigger: "item",
						formatter: (params) => {
							const name = params.name;
							let toolTiphtml = name + "<br/>";
							let item = params.data;
							let desInfo = this.desInfo;
							if (nameList.indexOf(name) >= 0) {
								if (this.tabIndex == 0) {
									if (item.value) {
										toolTiphtml +=
											`<div style="text-align: left;padding: 3px 0">房屋数量：${item.value}套</div>`;
										// desInfo.yjYpPgAmount = item.value;
									}
									if (item.cityYpPgAmount) {
										toolTiphtml +=
											`<div style="text-align: left;padding: 3px 0">总估值：${item.cityYpPgAmount}亿元</div>`;
										// desInfo.cityYpPgAmount = item.cityYpPgAmount;
									}

									// item.cityCnt && (desInfo.cityCnt = item.cityCnt);
									// item.yjYpCityCnt && (desInfo.yjYpCityCnt = item.yjYpCityCnt);
								} else {
									if (item.value) {
										toolTiphtml +=
											`<div style="text-align: left;padding: 3px 0">风险预警数量：${item.value}笔</div>`;
									}
									if (item.cityYjYpRate) {
										toolTiphtml +=
											`<div style="text-align: left;padding: 3px 0">比重：${item.cityYjYpRate}%</div>`;
										// desInfo.cityYpPgAmount = item.cityYpPgAmount;
									}
									if (item.cityYjYpPgAmount) {
										toolTiphtml +=
											`<div style="text-align: left;padding: 3px 0">风险押品总值：${item.cityYjYpPgAmount}</div>`;
									}
								}
							}
							// this.desInfo.yjYpPgAmount = item.value;
							// this.desInfo.yjYpPgAmount = item.value;
							return toolTiphtml;
						},
						textStyle: {
							color: "#000",
							fontSize: this.fontSize,
						},
						padding: [8, 15],
						backgroundColor: "rgba(255,255,255,.8)",
						// extraCssText : "borderRadius : '13px'; backgroundColor : 'rgba(255,255,255,.8)'; "
					},
					visualMap: {
						show: false, //去掉层级过滤显示
						min: 0,
						max: mapInfo[0] ? mapInfo[0].value : 100,
						inRange: {
							color: ["#00B6FF", "#00B6FF"],
						},
					},
					geo: {
						show: true,
						map: mapName,
						layoutCenter: ["40%", "50%"],
						layoutSize: "100%",
						roam: true, //是否可以放大缩小
						label: {
							normal: {
								show: false,
							},
							emphasis: {
								show: false,
							},
						},
						itemStyle: {
							normal: {
								areaColor: "rgb(255,245,240)",
								borderWidth: 1,
								borderColor: "#1E2E54",
							},
							emphasis: {
								areaColor: "#FEFF00",
							},
						},
						top: 10,
						left: 10,
						bottom: 10,
						right: 10,
					},
					series: [{
						type: "map",
						map: mapName,
						geoIndex: 0,
						aspectScale: 0.75, //长宽比
						showLegendSymbol: false, // 存在legend时显示
						label: {
							normal: {
								show: false,
							},
							emphasis: {
								show: false,
								textStyle: {
									color: "#fff",
								},
							},
						},
						roam: true,
						itemStyle: {
							normal: {
								areaColor: "#fff",
								borderColor: "#fff",
							},
							emphasis: {
								areaColor: "#fff",
							},
						},
						animation: false,
						data: mapInfo,
					}, ],
				};

				this.chart.setOption(this.option);
				this.showSpin = false;
			},
			setMap() {
				let data = [];
				if (this.tabIndex == 0) {
					data = this.totalData;
				} else {
					data = this.warnData;
				}

				let mapInfo = data.map((item) => {
					let name = item.cs;
					if (this.cityArr.indexOf(name) < 0) {
						this.allCityName.some((item) => {
							if (item.indexOf(name) == 0) {
								name = item;
								return true;
							}
						});
					}
					let value = this.tabIndex == 0 ? item.cityYpCnt : item.cityYjYpCnt;
					return {
						name,
						value,
						...item,
					};
				});

				// this.option.visualMap.max = mapInfo[0].value;
				this.option.visualMap.inRange.color =
					this.tabIndex == 0 ? ["#00B6FF", "#00B6FF"] : ["#FF6464", "#FF6464"];
				this.option.series[0].data = mapInfo;

				this.chart.setOption(this.option);
			},
			hideModal() {
				this.liuchengModal = false;
			},
			showModal() {
				this.liuchengModal = true;
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/style/common.scss";

	.page {
		@include flex(flex-start, flex-start);
		flex-direction: column;
		min-height: 100%;
		min-width: 1200px;
		padding: 20px;
		position: relative;

		.page-top {
			@include flex(flex-start);
			position: relative;

			.title {
				margin-left: 10px;
				margin-right: 40px;
				color: #1562d6;
				font-size: 18px;
				font-weight: bold;
			}

			.btn {
				@include flex;
				margin-right: 40px;
				color: #666;
				font-size: 14px;
				line-height: 1;
				cursor: pointer;

				.icon {
					width: 16px;
					height: 16px;
					margin-right: 10px;
				}

				&:nth-child(2) {
					.icon {
						@include bgSrc("pledge/task-icon.png");
					}
				}

				&:nth-child(3) {
					.icon {
						@include bgSrc("pledge/search-icon.png");
					}
				}

				&:nth-child(4) {
					.icon {
						@include bgSrc("pledge/liucheng-icon.png");
					}
				}

				&:hover {
					// color: $themeColor;
					// &:nth-child(2) {
					// 	.icon {
					// 		@include bgSrc('pledge/task-icon-hover.png');
					// 	}
					// }
					// &:nth-child(3) {
					// 	.icon {
					// 		@include bgSrc('pledge/search-icon-hover.png');
					// 	}
					// }
				}
			}

			&::before {
				width: 4px;
				height: 20px;
				background-color: #1562d6;
				content: "";
			}
		}

		.info-box {
			@include flex(flex-start);
			margin: 20px 0;

			.info-item {
				@include flex(flex-start, flex-start);
				width: 300px;
				height: 150px;
				margin-right: 30px;
				font-size: 14px;
				border-radius: 10px;
				border: 1px solid #e3e3e5;
				box-shadow: 0px 10px 10px 0px rgba(9, 110, 213, 0.1);

				.left {
					margin: 20px 30px 0;

					.icon {
						@include flex;
						width: 80px;
						height: 80px;
						margin-bottom: 20px;
						// border-radius: 50%;
						// background-color: #F3F7FF;
					}

					.txt {
						color: #999;
						text-align: center;
					}
				}

				.right {
					margin-top: 30px;

					.line {
						@include flex(flex-start);
						margin-bottom: 15px;
						white-space: nowrap;

						&-left {
							@include flex(flex-start);
							flex-shrink: 0;
							position: relative;
							width: 70px;
							margin-right: 20px;
							color: #1562d6;

							&::before {
								width: 6px;
								height: 6px;
								margin-right: 6px;
								border-radius: 50%;
								border: 1px solid #1562d6;
								content: "";
							}
						}

						&-right {
							color: #333;
						}
					}
				}

				&.danger {
					.line {
						// &-left {
						// 	color: #FF3333;
						// 	&::before {
						// 		border-color: #FF3333;
						// 	}
						// }
					}
				}

				&:nth-child(1) {
					.icon {
						@include bgSrc("pledge/progress.png");
					}
				}

				&:nth-child(2) {
					.icon {
						@include bgSrc("pledge/success.png");
					}
				}

				&:nth-child(3) {
					.icon {
						@include bgSrc("pledge/total.png");
					}
				}

				&:nth-child(4) {
					.icon {
						@include bgSrc("pledge/warn.png");
					}
				}
			}
		}

		.map-box {
			position: relative;
			width: 100%;
			flex-grow: 1;
			overflow: hidden;

			#map {
				// width: 100%;
				width: 1860px;
				height: 100%;
				min-height: 745px;
			}

			.ivu-icon-ios-redo {
				position: absolute;
				top: 20px;
				left: 20px;
				z-index: 2;
				color: #00b6ff;
				font-size: 36px;
				transform: rotateY(180deg);
				cursor: pointer;
			}

			.des-box {
				position: absolute;
				width: 270px;
				height: 354px;
				top: 60px;
				right: 40px;
				padding: 20px;
				background-color: #fff;
				border-radius: 10px;
				border: 1px solid rgba(227, 227, 229, 1);
				box-shadow: 0px 10px 10px 0px rgba(9, 110, 213, 0.1);

				.box-top {
					@include flex(flex-start);
					position: relative;
					color: #333;
					font-size: 18px;
					font-weight: bold;

					&::before {
						@include bgSrc("title-left-icon.png");
						width: 8px;
						height: 20px;
						margin-right: 10px;
						content: "";
					}
				}

				.box-ct {
					@include flex;
					flex-direction: column;
					margin-top: 20px;
				}

				.des-item {
					@include flex(flex-start);
					width: 100%;
					margin-bottom: 20px;
					font-size: 14px;

					.icon {
						@include flex;
						width: 60px;
						height: 60px;
						margin-left: 10px;
						margin-right: 20px;
					}

					.txt {
						color: #666;
					}

					.val {
						@include flex(flex-start);
						white-space: nowrap;

						span {
							margin-right: 10px;
							color: #1562d6;
							font-size: 20px;
						}
					}

					&:nth-child(1) {
						.icon {
							@include bgSrc("pledge/num-icon.png");
						}
					}

					&:nth-child(2) {
						.icon {
							@include bgSrc("pledge/money-icon.png");
						}
					}

					&:nth-child(3) {
						.icon {
							@include bgSrc("pledge/city-icon.png");
						}
					}
				}

				.box-bottom {
					@include flex(space-around);

					.btn {
						@include flex;
						color: #666;
						font-size: 14px;
						line-height: 1;
						cursor: pointer;

						&.active {
							color: $themeColor;

							&:nth-child(1) {
								.icon {
									@include bgSrc("pledge/house-icon-hover.png");
								}
							}

							&:nth-child(2) {
								.icon {
									@include bgSrc("pledge/warn-icon-hover.png");
								}
							}
						}

						.icon {
							width: 20px;
							height: 20px;
							margin-right: 2px;
						}

						&:nth-child(1) {
							.icon {
								@include bgSrc("pledge/house-icon.png");
							}
						}

						&:nth-child(2) {
							.icon {
								@include bgSrc("pledge/warn-icon.png");
							}
						}
					}
				}
			}

			.table-box {
				position: absolute;
				width: 100%;
				// height: 280px;
				left: 0;
				bottom: 0;
				transition: 0.3s all;

				.box-top {
					@include flex(flex-start);
					height: 60px;
					padding-left: 20px;
					color: #1e2e54;
					font-size: 20px;
					font-weight: bold;
					background-color: rgba(#fff, 0.8);

					.arrow {
						@include bgSrc("arrow.png");
						width: 10px;
						height: 16px;
						margin-left: auto;
						margin-right: 20px;
						cursor: pointer;
						transform: rotate(180deg);
						transition: 0.3s all;
					}
				}

				&.active {
					.arrow {
						transform: rotate(0);
					}
				}
			}
		}

		.modalP {
			padding: 5px 40px;
		}
	}
</style>
